/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: grey;
  color: #444;
  cursor: pointer;
  padding: 0px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border: none;
  outline: none;
  box-sizing: inherit;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: lightgrey;
    display: none;
    overflow: hidden;
}

.progressBarContainer{
  display: flex;
  
}



.red_{
  background-color: red;
}

.blue{
  background-color: blue;
}

.orange{
  background-color: orange;
}

hr{
  background-color: grey;
  margin: 5px;
  padding: .5px;
}

p{
  margin-top:1px;
  margin-bottom: 1px;
  margin-left: -1px;
  margin-right: -1px; 
  
}

.boxes{
  display: inline;
}

.infoContainer{
  margin-top:2px;
}



.float-child {
    flex-grow: 2;
    width: 20%;
    height: 10px;
    float: left;

    border: 1px solid black;
}

hr{
  width: 100%;
  

}

#a{
  margin-right:40px;
}