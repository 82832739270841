.box {
    float: left;
    height: 22px;
    width: 6000px;
    margin-bottom: 15px;
    clear: both;
    text-align: center;
}

.second{
    background-color: #bbf0fc;
    border: 1px solid black;
}

.third{
    background-color: #ff6200;
    border: 1px solid black;
}

.fourth{
    background-color: #8B0000;
    border: 1px solid black;
}

.sixth{
    background-color: #ffd000;
    border: 1px solid black;
}

.seventh{
    background-color: #b9ff69;
    border: 1px solid black;
}

.eighth{
    background-color: #89ff00;
    border: 1px solid black;
}

.ninth{
    background-color: #70d100;
    border: 1px solid black;
}

.tenth{
    background-color: #00a82d;
    border: 1px solid black;
}

.eleventh{
    background-color: #c9c9c9;
    border: 1px solid black;
}

.twelfth{
    background-color: #9e9d9d;
    border: 1px solid black;
}

.thirteenth{
    background-color: #000000;
    border: 1px solid black;
}

.fifteenth{
    background-color: #0077ff;
    border: 1px solid black;
}

.sixteenth{
    background-color: #0040d6;
    border: 1px solid black;
}

.seventeenth{
    background-color: #002885;
    border: 1px solid black;
}

.nineteenth{
    background-color: #855900;
    border: 1px solid black;
}


/* Unfilled */
.second-unfilled{
    border: 3px solid #bbf0fc;
}

.third-unfilled{
    border: 3px solid #ff6200;
}

.fourth-unfilled{
    border: 3px solid #8B0000;
}

.sixth-unfilled{
    border: 3px solid #ffd000;
}

.seventh-unfilled{
    border: 3px solid #b9ff69;
}

.eighth-unfilled{
    border: 3px solid #89ff00;
}

.ninth-unfilled{
    border: 3px solid #70d100;
}

.tenth-unfilled{
    border: 3px solid #00a82d;
}

.eleventh-unfilled{
    border: 3px solid #c9c9c9;
}

.twelfth-unfilled{
    border: 3px solid #9e9d9d;
}

.thirteenth-unfilled{
    border: 3px solid #000000;
}

.fifteenth-unfilled{
    border: 3px solid #0077ff;
}

.sixteenth-unfilled{ 
    border: 3px solid #0040d6;
}

.seventeenth-unfilled{
    border: 3px solid #002885;
}

.nineteenth-unfilled{
    border: 3px solid #855900;
}

/* tool Tips */
.box:hover .toolTipText{
    visibility: visible;
  }
  
  .box .toolTipText{
    visibility: hidden;
    width:auto;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    
    border: black solid 1px;
  
    pointer-events: none;
    /* Position the tooltip */
    position: absolute;
    z-index: 100000;
    top: 63.5px;
    left: 3.3%;
  }


