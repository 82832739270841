.multicolor-bar .legends {
	text-align: center;
}

.multicolor-bar .legends .legend {
	display: inline-block;
	margin: 1px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
	font-size: 40px;
	vertical-align: middle;
}

.legends {
	background-color: white;
	padding-top: 1px;
}

.label{
	font-size: 1.4em;
	padding: 2px;
	margin: 2px;
	display:inline-block;
}

.labelMobile{
	font-size: 1.0em;
	padding: 1px;
	margin: 0px;
	display:inline-block;
}
