h2 {
  color: black;
}

#bttn {
  width: 100px;
  height: 50px;
  margin-bottom: 5px;
}

#bbb {
  border-radius: 3%;
}

.one {
  width: 95%;
  height: 20%;
  background: lightblue;
  border-radius: 25px;
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 12px;
  border: 2px;
  border-style: solid;
}

.twoAnalytics {
  width: 30%;
  height: 36%;
  border-radius: 25px;
  margin-top: 1px;
  margin-left: 10px;
  margin-bottom: 12px;
  background: lightblue;
  float: left;
  border: 2px;
  border-style: solid;
}

.threeAnalytics {
  width: 65%;
  height: 36%;
  background: lightblue;
  border-radius: 25px;
  margin-top: 1px;
  margin-left: 33%;
  margin-bottom: 12px;
  border: 2px;
  border-style: solid;
}

.four {
  width: 95%;
  height: 30%;
  background: lightblue;
  border-radius: 25px;
  margin-top: 12px;
  margin-left: 10px;
  margin-bottom: 2px;
  border: 2px;
  border-style: solid;
}
.container {
  background-color: white;
  padding: 0;
  margin: 0;
  height: 850px;
 
}

a {
  color:rgb(155, 157, 159);
}

#link1{
  font-size: x-large;
  width: 120px;
  margin-top: 1px;
  border-radius: 5px;
  color: white;
  background-color: rgb(43, 131, 213);
  align-self: center;
  font-size: x-large;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

#link2{
  font-size: x-large;
  width: 120px;
  margin-top: 1px;
  border-radius: 5px;
  color: white;
  background-color: rgb(36, 186, 146);
  align-self: center;
  font-size: x-large;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; 
}



#latestOrder{
  margin: 8.5px;
  width:auto;
  height: 120px;
  background-color: white;
  border-radius: 10px;
}
/* Joyson (Hoosier Metalform (1pcs, Die)) */
/* Target Shipment Date: 02/15/2022 */

#numberCurrentOrders{
  margin: 8.5px;
  width:auto;
  height: 95%;
  background-color: white;
  border-radius: 10px;
}

#goals{
  margin: 8.5px;
  width:auto;
  height: 95%;
  background-color: white;
  border-radius: 10px;
}



#logoutButton{
  margin-top: 1px;
  width: 120px;
  border-radius: 5px;
  background-color: rgb(221, 54, 68);
  align-self: center;
  font-size: x-large;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 0;
  color: white;
  gap: 10px;
}

.chart{
  padding-bottom: 30px;
}

#numCurrent{
  font-size: 50px;
}