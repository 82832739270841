.btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    
  }
  
  #username {
    text-align: center;
  }
  
  #password {
    text-align: center;
  }
  

 .container{
 
    width: 100%;
    height: 100vh;
    text-align: center;
    background-color: rgb(16,117,193);
    align-items: center;
    max-width: unset;
}

.MobileSigninRow{
    position: relative;
    top: 40%;
    transform: translateY(-50%);
}

.logo{
    display:block;
    margin:auto;
    width: 100%;
}