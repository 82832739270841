@font-face {
  font-family: Domine; 
   src: url('Fonts/DomineFont/Domine-Regular.ttf');
}

@font-face {
   font-family: Accuratist; 
   src: url('Fonts/AccuratistFont/accuratist-webfont.woff2');
}

@font-face {
   font-family: Oxygen Mono, monospace;
  src: url('https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap')
}

*{
  font-family: Domine;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(189 235 243) !important;
  
}


button.exportBtn {
	margin-top: 2em;
    width: 200px;
    height: 5em;
    background-color: #0066CC;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-left: 1em;
}

button.exportBtn:hover {
    background-color: #001a33;
    border: none;
}

.exportAllBtn {
    margin-top: 2em;
    width: 200px;
    height: 5em;
    background-color: #0066CC;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-left: 1em;
}

.smallBtn {
  margin-top: auto !important;
  width: 25% !important;
  height: 3em !important;
  background-color: #0066CC !important;
  border: none;
  color: white;
  padding: 3px 5px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 1em;
}

.smallBtn2 {
  margin-top: auto !important;
  width: 15% !important;
  height: 3em !important;
  background-color: #0066CC !important;
  border: none;
  color: white;
  padding: 3px 5px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 1em;
}

.smallBtn3 {
  margin-top: auto !important;
  width: 40% !important;
  height: 5em !important;
  background-color: #0066CC !important;
  border: none;
  color: white;
  padding: 3px 5px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 1em;
}

.smallBtn5 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 10% !important;
  height: 2em !important;
  background-color: #0066CC !important;
  border: none;
  color: white;
  padding: 3px 5px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 1em;
}

.smallBtn4 {
  margin-top: auto !important;
  width: 100% !important;
  height: 5em !important;
  background-color: #0066CC !important;
  border: none;
  color: white;
  padding: 3px 5px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 1em;
}

button[type="submit"] {
    width: 200px;
    height: 5em;
    background-color: #0066CC;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

button[type="submit"]:hover {
    background-color: #001a33;
}

.exportAllBtn:hover {
    background-color: #001a33;
}

button.btn.buttonMove {
	margin-top: 2em;
    width: 200px;
    height: 5em;
    background-color: #0066CC;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
	margin-bottom: 2em;
}

button.btn.buttonMove:hover {
	background-color: #001a33;
}

button.ui.button {
    margin-top: 2em;
    width: 200px;
    height: 5em;
    background-color: #0066CC;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 2em;
    border-radius: 0px;
}

button.ui.primary.button:hover {
    background-color: #001a33;
    color: white;
}

button.ui.button:hover {
    background-color: #001a33;
    color: white;
}

button.ui.icon {
	margin-top: initial;
	width: initial;
	height: initial;
	display: inline;
	text-align: initial;
}

button.ui.icon:hover {
    background-color: #001a33;
    color: white;
}

/*tr:nth-child(even) {
  background-color: #F0F0F0;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
.blueBackground {
  background-color: rgba(92, 206, 226, 0.4);
}
*/


.blueBackground {
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: rgb(189 235 243);
}
